@import 'Assets/styles/common.scss';

#cj-layout {
  width: 100%;
  height: 100%;

  // padding: 10px 25px 25px 25px;
  position: relative;

  .cj-layout-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
    position: relative;
    height: 144px;
    padding: 0px var(--sp_lv_6);
    color: #00000073;
    background-color: var(--white);
    .dropdown-container {
      display: flex;
      height: 25px;
      .ant-dropdown-trigger {
        margin-right: var(--sp_lv_4);
        cursor: pointer;
        margin-bottom: 0px;
      }
    }

    .cj-name {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 1000px;
      text-align: left;
      margin-top: 16px;
      &__down {
        margin-left: 10px;
      }

      &__title {
        text-align: left;
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        border: none;
        background: none;
        padding: 0 0px;
        min-width: 24px;
        color: #00000073;
        font-weight: 600;

        &:focus {
          outline: none;
        }
      }

      .anticon {
        font-size: 20px;
        color: var(--brand_primary);
      }

      .ant-skeleton-button {
        border-radius: 10px;
        width: 100px;
      }
    }

    .cj-actions {
      &__close {
        padding: unset !important;
        color: var(--primary_text) !important;
        display: flex;
        align-items: center;
        position: absolute;
        top: 12px;
        left: 12px;

        .anticon {
          display: flex;
          align-items: center;
        }
      }

      &__primary {
        color: #000000 !important;
        background-color: #ffffff !important;
        border: solid 1px #00000026 !important;
        @keyframes bounce {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.98);
          }
        }

        &.is-modified {
          animation: bounce 3s ease infinite;
        }
        &.to-background_disabled {
          background-color: #f0f0f0 !important;
        }
      }
    }
  }

  .cj-left-sidebar,
  .cj-right-sidebar {
    position: absolute;
    z-index: 30;
    top: 0;
  }

  .cj-left-sidebar {
    left: 0;
  }

  .cj-right-sidebar {
    right: 0;
  }
}

.cj-layout-info {
  .ant-modal-confirm-content {
    margin: 0 !important;
  }

  .cj-info-wrapper {
  }

  .cj-info-item {
    width: 220px;
    margin-bottom: 20px;

    &__label {
      color: var(--gray_9);
    }

    &__value {
    }
  }
}

path.node-line.split-type {
  // stroke-dasharray: 5;
  animation: dash 30s infinite linear;
}

// @keyframes dash {
//     to {
//         stroke-dashoffset: -500;
//     }
// }

.cj-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(var(--color), $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
